import { UserType } from '@shared/enums';
import { RESOURCE_PERMISSION } from '@shared/role-bace-acces-controller/resource-permission.constant';
import { attendancePageMenuItems } from '@pages/attendance/attendance.page';
import { computed, inject, Signal } from '@angular/core';
import { IMenuRoutes } from './menu-routes.interface';
import { userManagementPageRoutes } from '@pages/user-management/user-management.page';
import { announcementsPageMenuItems } from '@pages/announcements/announcements-home.component';
import { courseManagementPageMenuItems } from '@pages/course-management/course-management-home.page';
import {
  settingPermissions,
  settingsPageMenuItems,
} from '@pages/settings/settings.page';
import { helpCenterPageMenuItems } from '@pages/help-center/help-center.page';
import { AuthService } from '@auth/auth.service';

export function mainMenuRoutes(): Signal<IMenuRoutes[]> {
  const attendancePageRoutes = attendancePageMenuItems();
  const helpCenterPageRoutes = helpCenterPageMenuItems();
  return computed(() => [
    {
      path: 'home',
      titleI18nKey: 'global.home.title',
      iconName: 'saxHome2Outline',
      placement: 'upper',
    },
    {
      // my courses route for students and guardians
      path: 'my-courses',
      titleI18nKey: 'global.my_courses.title',
      iconName: 'saxBook1Outline',
      // TODO: update permission ids and hide for admins
      permissions: [
        RESOURCE_PERMISSION.COURSE_CONTENT.READ.COURSES_LIST_STUDENTS,
      ],
      isHide: false,
      UserTypes: [UserType.STUDENT, UserType.GUARDIAN],
      placement: 'upper',
    },
    {
      path: 'user-management',
      titleI18nKey: 'global.user_management.title',
      iconName: 'saxUserSearchOutline',
      permissions: [
        RESOURCE_PERMISSION.student.viewStudentsList,
        RESOURCE_PERMISSION.personnel.viewPersonnelList,
        RESOURCE_PERMISSION.guardians.viewGuardiansList,
      ],
      UserTypes: [UserType.PERSONNEL],
      subRoutes: userManagementPageRoutes(),
      placement: 'upper',
    },
    {
      path: 'school-structure',
      titleI18nKey: 'global.school_structure.title',
      iconName: 'saxBuildings2Outline',
      permissions: [RESOURCE_PERMISSION.company.viewCompanyList],
      placement: 'upper',
    },
    {
      path: 'course-management',
      titleI18nKey: 'global.course_management.title',
      iconName: 'saxBook1Outline',
      permissions: [
        RESOURCE_PERMISSION.course.courseListView,
        RESOURCE_PERMISSION.COURSE_CONTENT.READ.COURSES_LIST_TEACHERS,
      ],
      subRoutes: courseManagementPageMenuItems(),
      placement: 'upper',
    },
    {
      path: 'announcements',
      titleI18nKey: 'global.announcements.title',
      iconName: 'announcements',
      iconClass: 'text-[2.25rem]',
      permissions: [RESOURCE_PERMISSION.announcement.announcementListView],
      subRoutes: announcementsPageMenuItems(),
      placement: 'upper',
    },
    {
      path: 'chat',
      titleI18nKey: 'global.chats.title',
      iconName: 'saxMessages3Outline',
      permissions: [RESOURCE_PERMISSION.chat.chatListView],
      placement: 'upper',
    },
    {
      path: 'attendance',
      titleI18nKey: 'global.attendance.title',
      iconName: 'saxUserOctagonOutline',
      isHide: false,
      permissions: [
        RESOURCE_PERMISSION.attendance.LIST,
        RESOURCE_PERMISSION.attendance.DETAIL,
        RESOURCE_PERMISSION.attendance.CREATE,
        RESOURCE_PERMISSION.attendance.UPDATE,
        RESOURCE_PERMISSION.attendance.CONFIRM_ABSENCE,
      ],
      subRoutes: attendancePageRoutes(),
      placement: 'upper',
    },
    {
      path: 'support-tickets',
      titleI18nKey: 'global.support_ticket.title',
      iconName: 'saxTicketOutline',
      permissions: [RESOURCE_PERMISSION.supportTicket.viewMyAssignedTickets],
      isHide: true,
      notificationUnreadCountFn: (notification) =>
        notification?.tickets?.assigneeUnreadCounts,
      placement: 'upper',
    },
    {
      path: 'reports',
      titleI18nKey: 'global.reports.title',
      iconName: 'saxDocumentCopyOutline',
      permissions: [...Object.values(RESOURCE_PERMISSION.REPORTS.READ)],
      isHide: true,
      placement: 'upper',
    },
    {
      path: 'vcr',
      titleI18nKey: 'global.virtual_classrooms_vcr.title',
      iconName: 'open-book',
      isHide: true,
      placement: 'upper',
      permissions: [...Object.values(RESOURCE_PERMISSION.VCR.READ)],
    },
    {
      path: 'pickup/history',
      titleI18nKey: 'dismissal.pickup_history.title',
      iconName: 'saxRefreshOutline',
      isHide: true,
      placement: 'upper',
      permissions: [
        RESOURCE_PERMISSION.DISMISSAL.READ.VIEW_DISMISSAL_LIST_GUARDIAN,
      ],
      isHideFromSuperAdmin: true,
    },
    {
      path: 'pickup/personnel',
      titleI18nKey: 'global.pickup_requests.title',
      iconName: 'saxCarOutline',
      isHide: true,
      placement: 'upper',
      permissions: [
        RESOURCE_PERMISSION.DISMISSAL.READ.VIEW_DISMISSAL_LIST_PERSONNEL,
      ],
      isHideFromSuperAdmin: true,
    },
    {
      path: 'help-center',
      titleI18nKey: 'global.help_center.title',
      iconName: 'saxInfoCircleOutline',
      placement: 'lower',
      isPublic: true,
      subRoutes: helpCenterPageRoutes(),
      notificationUnreadCountFn: (notification) =>
        notification?.tickets.initiatorUnreadCounts,
    },
    {
      path: 'settings',
      titleI18nKey: 'global.settings.title',
      iconName: 'saxSetting2Outline',
      placement: 'lower',
      permissions: settingPermissions(),
      subRoutes: settingsPageMenuItems(),
    },
  ]);
}
